import React, { useEffect } from 'react';
import AOS from 'aos';

export const Books = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id='books'>
      <div className='container'>
        <div
          className='section-title text-center about-text'
          data-aos='fade-down'
        >
          <h2>Books</h2>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <img
              src='img/awakening-collection.png'
              className='img-responsive'
              alt='awakening book collection'
              data-aos='zoom-in'
              style={{ margin: '0px auto' }}
            />
            <div
              style={{ textAlign: 'center', padding: '100px 0px 0px 0px' }}
              data-aos='fade-right'
              data-aos-delay='300'
            >
              <a href='/' className='btn btn-custom btn-lg page-scroll'>
                Buy On Amazon Store
              </a>
            </div>
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text' data-aos='fade-left'>
              <p className='welcome-paragraph'>
                Experience a transformational journey with Jerry Troyer as he
                explores the very idea of God's existence and takes readers on a
                daring expedition into the realms of doubt, disbelief, and
                uncertainty surrounding the greatness of God. Through powerful
                storytelling and thought-provoking narratives based on real-life
                situations, Troyer invites readers to imagine their own
                judgments if they were a deity, followed by captivating
                responses from the perspective of God as supported by Scripture.
                The result is easy to digest teachings.
                <br />
                <br />
                <ul className='question-list'>
                  <li>
                    Gain a deeper understanding of your own beliefs and doubts
                  </li>
                  <li>
                    Connect with a higher purpose and find meaning in a complex
                    world
                  </li>
                  <li>
                    Be inspired to examine traditional boundaries of faith and
                    skepticism
                  </li>
                  <li>Discover a unique perspective of the divine mind</li>
                  <li>
                    Unleash your potential for personal growth and fulfillment
                  </li>
                </ul>
                <br />
                If you're weary of trying to control your own life, it's time to
                surrender to the goodness of God and awaken to the truth of His
                perfect wisdom and mercy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
