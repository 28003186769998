import React, { useEffect } from 'react';
import AOS from 'aos';

const WelcomeNote = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      // easing: 'ease-in-out-sine',
    });
  }, []);
  return (
    <div data-aos='fade-up' id='welcome-note'>
      <div className='container'>
        <div className='row '>
          <div data-aos='flip-left' className='col-xs-12 col-md-6'>
            <img
              src='img/awakening-book-2.png'
              className='img-responsive'
              alt='Awakening the Truth Book Cover'
              style={{ margin: '0px auto' }}
            />
          </div>
          <div className='col-xs-12 col-md-6'>
            <div data-aos='fade-left' className='about-text'>
              <h2>Hello and Welcome</h2>
              <p className='welcome-paragraph'>
                I’m Jerry Stephen Troyer, and I’m so glad you’ve found your way
                here. Whether you’re a seeker of deeper spiritual understanding,
                a curious mind exploring the complexities of faith, or someone
                navigating your own journey of belief, this space is for you.
                <br /> <br />
                My debut book, Awakening to the Truth, was written with you in
                mind. It’s a work crafted with your questions, discoveries, and
                moments of clarity that I hope will resonate with you. I believe
                that the search for truth is a path you and I walk together, and
                I’m honored to share my thoughts and insights with you as we
                connect together in this book. <br /> <br />
                On this site, you’ll find more than just information about my
                book. I’ll be sharing information to help you as you venture
                through the upcoming days. There will regular blog posts to
                provide you inspiration, I will be giving updates on upcoming
                projects, and I will provide you with other resources that will
                inspire and encourage you in your own journey.
                <br /> <br />
                Feel free to explore, leave comments, and connect with me—I’m
                here for you. To listen, interact and learn from you as well.
                <br /> <br />
                Thank you for visiting. I look forward to walking this path with
                you.
                <br /> <br />
                Please click the link below which will take you to the site to
                buy my book. You will also find on this website a button to
                click to sign up for my newsletter.
              </p>
              <img
                src='img/awakening-signature.png'
                className='img-responsive'
                alt='signature'
                style={{ width: '200px', marginLeft: 'auto', marginRight: '0' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeNote;
