import React, { useEffect } from 'react';
import AOS from 'aos';

export const NewProject = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6' data-aos='fade-right'>
            <div className='about-text'>
              <h2>Coming Soon - 2025</h2>
              <p>
                I'm thrilled to share that I've embarked on an exciting new
                journey as the biographer of an athlete who led her team to
                playing in a championship game. This project is more than just a
                recount of achievements; it's a deep dive into the life,
                mindset, and experiences of an athlete who, as a teen, was
                growing in maturity and ability both on and off the field. Here
                are two links to excerpts of the book:
                <br /> <br />
                <a
                  href='/excerps/excerp-2.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Read Excerpt 1
                </a>
                <br /> <br />
                <a
                  href='/excerps/excerp-1.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Read Excerpt 2
                </a>
              </p>
            </div>
          </div>
          <div className='col-xs-12 col-md-6' data-aos='zoom-in'>
            <img
              src='img/awakening-team.png'
              className='img-responsive'
              alt='Project Idea'
              style={{ margin: '0px auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
