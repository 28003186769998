import React, { useEffect } from 'react';
import AOS from 'aos';

const MediaQuestions = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center' data-aos='fade-down'>
          <h2>Media Questions</h2>
        </div>
        <div className='row'>
          <div
            className='question-section col-xs-12 col-md-6'
            data-aos='fade-right'
          >
            <h3 className='question-category'>About the Book</h3>
            <ul className='question-list'>
              <li>
                What inspired you to write <em>Awakening to the Truth</em>, and
                what message do you hope readers take away from it?
              </li>
              <li>
                Can you share a pivotal moment or chapter in the book that holds
                special significance for you?
              </li>
              <li>
                How does your background in biblical counseling and pastoral
                care influence the themes in your book?
              </li>
            </ul>
          </div>

          <div
            className='question-section col-xs-12 col-md-6'
            data-aos='fade-left'
          >
            <h3 className='question-category'>About the Author's Journey</h3>
            <ul className='question-list'>
              <li>
                You have a diverse career in spiritual guidance and
                counseling—how did these experiences shape your approach to
                writing?
              </li>
              <li>
                What are some of the most profound lessons you’ve learned from
                your work with individuals and couples in crisis?
              </li>
              <li>
                In your journey from financial planning to spiritual counseling,
                what motivated you to make such a significant career shift?
              </li>
            </ul>
          </div>

          <div
            className='question-section col-xs-12 col-md-6'
            data-aos='fade-right'
          >
            <h3 className='question-category'>On Faith and Spirituality</h3>
            <ul className='question-list'>
              <li>
                How do you see the relationship between faith and practical life
                challenges, such as financial difficulties?
              </li>
              <li>
                What advice would you give to someone who is struggling with
                their faith or questioning their beliefs?
              </li>
              <li>
                How do you approach counseling those who are grieving or facing
                a crisis, and what role does faith play in your guidance?
              </li>
              <li>
                What are your top three tips for couples entering into marriage?
              </li>
              <li>
                If someone is in financial crisis, what one thing can they do to
                get back on track?
              </li>
            </ul>
          </div>

          <div
            className='question-section col-xs-12 col-md-6'
            data-aos='fade-left'
          >
            <h3 className='question-category'>Future Projects and Goals</h3>
            <ul className='question-list'>
              <li>
                What are your goals for the future, both as an author and in
                your work as a spiritual counselor?
              </li>
              <li>
                How do you envision the impact of{' '}
                <em>Awakening to the Truth</em> on your readers and your
                community?
              </li>
            </ul>
          </div>

          <div
            className='question-section col-xs-12 col-md-6'
            data-aos='fade-left'
          >
            <h3 className='question-category'>Personal Reflections</h3>
            <ul className='question-list'>
              <li>
                What has been the most rewarding aspect of your work in
                spiritual guidance and writing?
              </li>
              <li>
                How do you balance your various roles—author, chaplain,
                counselor, and leader—on a daily basis?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaQuestions;
