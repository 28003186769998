import React, { useEffect } from 'react';
import AOS from 'aos';

export const About = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      // easing: 'ease-in-out-sine',
    });
  }, []);
  return (
    <div data-aos='fade-up' id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <div data-aos='fade-right' className='about-text'>
              <h2>About</h2>
              <p className='welcome-paragraph'>
                Jerry Troyer is a passionate storyteller with a deep love for
                the Christian faith. Throughout his life he has always been
                drawn to the world of words, spending countless hours immersed
                in books that sparked his imagination. A great portion of his
                life has been spent listening to people’s stories, hearing those
                seeking counsel and delivery words of support to those seeking
                it. <br /> <br />
                Awakening to the Truth is the culmination of years of
                dedication, drawing inspiration from influences, education, and
                study and life experiences. With a background in ministry and
                Christian education Jerry has honed a work with a thoughtful and
                engaging voice that speaks directly to both seekers of
                Christianity and non-believers. His journey as a writer is
                centered in relationships, counseling clients, Christian studies
                and personal reflections. This book is a culmination of years of
                exploration and dedication in his spiritual journey and diverse
                experiences of those he has encountered.
              </p>
            </div>
          </div>
          <div data-aos='zoom-in-down' className='col-xs-12 col-md-6'>
            <img
              src='img/Troyer-J.jpg'
              className='img-responsive'
              alt='snapshot'
              style={{ margin: '0px auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
