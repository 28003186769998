import React, { useEffect } from 'react';
import AOS from 'aos';

export const Testimonials = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div id='testimonials'>
      <div className='container'>
        <div data-aos='fade-up' className='section-title text-center'>
          <h2>Testimonials</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className='col-md-6'
                  data-aos='fade-up'
                  data-aos-delay={`${i * 200}`} // Adds a delay for each testimonial
                >
                  <div className='testimonial'>
                    <div
                      data-aos='zoom-in'
                      className='testimonial-image'
                      style={{
                        position: 'relative',
                      }}
                    >
                      <span>
                        <i
                          style={{
                            color: '#eba028',
                            position: 'absolute',
                            top: '18px',
                            left: '18px',
                          }}
                          className='fa fa-certificate'
                          aria-hidden='true'
                        ></i>
                      </span>
                    </div>
                    <div data-aos='fade-left' className='testimonial-content'>
                      <p>"{d.text}"</p>
                      <p className='testimonial-meta'>- {d.name}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
        <div
          style={{ textAlign: 'center', padding: '26px 0px' }}
          data-aos='fade-up'
        >
          <a
            href='https://www.Calendly.com/vesselsofhopemissions'
            className='btn btn-custom btn-lg page-scroll'
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact Jerry
          </a>
        </div>
      </div>
    </div>
  );
};
