import React, { useState, useEffect } from 'react';
import AOS from 'aos';

const modalStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: '1050',
};

const modalContentStyles = {
  backgroundColor: '#fff',
  borderRadius: '12px',
  maxWidth: '550px',
  minWidth: '380px',
  maxHeight: '70%',
  overflowY: 'auto',
  boxShadow: '0 4px 8px #00000033',
  position: 'absolute',
  margin: '0px 20px 0px 20px',
};

const closeButtonStyles = {
  background: 'none',
  border: 'none',
  fontSize: '32px',
  fontWeight: 600,
  cursor: 'pointer',
  position: 'absolute',
  top: '4px',
  right: '10px',
  color: '#000',
};

export const Media = () => {
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showPressReleaseModal, setShowPressReleaseModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const handleClose = () => {
    setShowExperienceModal(false);
    setShowPressReleaseModal(false);
  };

  return (
    <div id='media'>
      <div className='container'>
        <div className='section-title' data-aos='fade-down'>
          <h2>Media</h2>
        </div>
        <div
          style={{
            borderRadius: '50%',
            width: '200px',
            height: '200px',
            margin: '0px auto',
            overflow: 'hidden',
            marginBottom: '20px',
          }}
          className='media-image'
          data-aos='zoom-in'
        >
          <img
            style={{
              width: '100%',
              position: ' absolute',
              top: '-32px',
              left: '0px',
            }}
            src='img/Troyer-J.jpg'
            alt='Jerry Stephen Troyer Snapshot'
            // className='img-responsive'
          />
        </div>
        <h4 data-aos='fade-up'>Jerry Stephen Troyer</h4>
        <p data-aos='fade-up'>Accomplished Christian Leader</p>
        <p className='media-paragraph' data-aos='fade-up'>
          Jerry Stephen Troyer is an accomplished Christian leader with
          extensive experience in various aspects of spiritual guidance,
          including biblical counseling, pastoral care, and crisis management.
          His career spans multiple roles, including Chaplain at Camp
          Pendleton's Edson Range Chapel, CEO of a non-profit organization
          focused on biblical financial counseling, and ordained elder in
          several church communities. With a strong foundation in financial
          planning, Jerry also offers premarital counseling, grief counseling,
          and has presided over memorial services.
          <br />
          <br /> Jerry is recognized for his organizational skills,
          interpersonal communication, and his ability to mentor and train
          others. He has provided spiritual guidance in both in-person and
          online settings, working with people from diverse backgrounds. His
          education includes studies in Christian Counseling, Biblical Studies,
          and various chaplaincy trainings, highlighting his commitment to
          faith-based service.
          <br />
          <br /> Jerry is a leader with diversified experience, poised to
          transform service opportunities within organizations. Offer team
          leadership and guidance. Behavioral management and conflict
          resolution. Keenly aware and cognizant of crises management and grief
          counseling. Exceptionally organized and disciplined. Possesses
          well-developed interpersonal skills and along with the ability to
          inspire, encourage and guide others. Brings business acumen and team
          management along with multi-layered education and application
          practices. Client service development and systems knowledge. Follows
          best practice and regulations in all areas. Developed and defined
          counseling and interactions with individuals, groups and large
          audiences.
        </p>
        <div style={{ marginTop: '30px' }} data-aos='fade-up'>
          <p
            className='media-paragraph'
            style={{ color: '#eba028', fontWeight: '600' }}
            onClick={() => setShowExperienceModal(true)}
          >
            Professional Experience
          </p>
          <p
            className='media-paragraph'
            style={{ color: '#eba028', fontWeight: '600' }}
            onClick={() => setShowPressReleaseModal(true)}
          >
            Press Release
          </p>
        </div>
      </div>
      {showExperienceModal && (
        <div style={modalStyles}>
          <div style={modalContentStyles}>
            <button
              onClick={handleClose}
              style={closeButtonStyles}
              aria-label='Close modal'
            >
              &times;
            </button>
            <div
              style={{ background: '#f1e0c2cf', padding: '20px' }}
              className=' text-center'
            >
              <h3 style={{ margin: '0px', fontWeight: 700 }}>
                Professional Experience
              </h3>
            </div>

            <div
              style={{
                padding: '20px ',
                textAlign: 'left',
              }}
              className='media-text'
            >
              <ul className='media-text'>
                <li>
                  Current: civilian Chaplain – Camp Pendleton, Edson Range
                  Chapel. Have been serving for five years. Oceanside, CA,
                  providing faith based instruction and personal prayer to
                  Marine’s.
                </li>
                <li>Current: Appointed Ordained Chaplain in 2019..</li>
                <li>
                  Current: Provide Christian/Biblical Counseling to individual
                  and couples. Since graduating with a degree in Christian
                  counseling.
                </li>
                <li>
                  Current: Vessels of Hope Missions a 501 (c) 3 non-profit -
                  Founder & CEO and Chaplain of biblical financial counseling.
                  Program manager and educator. Implement client corrective
                  procedures. Provide biblical financial counseling to
                  individuals and families experiencing financial difficulties
                  or crises. Application of practical financial living as
                  Scripture instructs. Monitor client progress. Grief counseling
                  from Gospel guidelines for those embracing and enduring
                  financial failure. Mentored, trained, and developed employees
                  as they encountered working with clients. Built and monitored
                  financial programs for clients to utilize. Developed a tool
                  for delinquent clients to utilize when speaking with
                  creditors.
                </li>
                <li>
                  Current: Premarital counseling and preside over ceremonies.
                  Perform memorial services and grief counseling for those in
                  mourning. Providing for sixteen years.
                </li>
                <li>
                  2018, Dec: Provided grief and crises counseling as Chaplain of
                  Billy Graham Evangelistic Association/Rapid Response team in
                  Paradise, CA.
                </li>
                <li>
                  2018: Completed Chaplain Orientation – Billy Graham
                  Evangelistic Association/Rapid Response Team.
                </li>
                <li>
                  2014-2016: Served as online Coach Groundwire.net. Provided
                  answers to people’s question from around the world, all walks
                  of life, faith and unbelief. Impacted lives by sharing the
                  hope and love of Jesus Christ. Praying over them and their
                  situation. Giving Biblical references for them to refer to.
                  Guiding people into the journey of salvation.
                </li>
                <li>
                  2011-2013: Global Media Outreach – Online volunteer counselor.
                  Direct communication to people around the globe. Providing
                  answers and insights to questions about Jesus. Whether
                  believers, seekers on non-believers. Putting people on a path
                  to salvation. Praying for them and with them. Providing
                  Biblical references for their future use.
                </li>
                <li>
                  2012-2017: Ordained elder Cloudbreak Church, San Diego, CA
                  (loosely tied to Southern Baptist Assoc.). Small group leader
                  and overseer of individual care.
                </li>
                <li>
                  2007-2011: Associate Pastor at East Village Community Church,
                  San Diego, CA. License was issued through Transformation
                  Ministries, Covina, CA. Responsibilities included oversight of
                  Small Groups & Sunday School classes, Budgeting and Financial
                  oversite, Preaching, lead of Wednesday night Bible study,
                  nurture and counsel congregants, lead of financial literacy
                  training, work directly with the Senior Pastor in church
                  development and faith growth of congregants. Small group
                  leader. Men’s Sunday school teacher.
                </li>
              </ul>
            </div>
            <h3
              style={{
                fontWeight: 700,
              }}
            >
              EDUCATION
            </h3>
            <div
              style={{
                padding: '20px',
                textAlign: 'left',
              }}
            >
              <ul className='media-text'>
                <li>
                  2025 scheduled to complete: Doctor of Divinity, Newburgh
                  Theological Seminary
                </li>
                <li>
                  2022-2024: Newburgh Theological Seminary, Major – B.A. -
                  Christian Counseling.
                </li>
                <li>
                  2018-2021: Calvary Chapel University, San Diego, CA. Major:
                  Biblical Studies with emphasis in Biblical Counseling.
                </li>
                <li>
                  2018-2019: Living Shield Ministries, Riverside, CA. 40 Hour
                  Chaplaincy training in grief and crises counseling.
                  Additionally, Pastoral Crises Intervention I &amp; II,
                  including grief training to first responders.
                </li>
                <li>
                  2018: Billy Graham Evangelistic Association/Rapid Response
                  Team Chaplain training. Additionally completing a course –
                  Sharing Hope in Crises.
                </li>
                <li>2014: Lead Like Jesus training.</li>
                <li>2014: Groundwire.net training.</li>
                <li>2011: Global Media Outreach training.</li>
                <li>
                  2011 – 2013: Attended Southern California Seminary, El Cajon,
                  CA.
                </li>
                <li>
                  2006 – Certificate of completion “Mastering Your Money”
                  program.
                </li>
                <li>
                  1986-1988: Graduate School of Banking, Madison, Wisconsin:
                  Graduate degree – Banking Administration.
                </li>
                <li>1984-1985: Hamilton College, Evanston WY</li>
                <li>
                  1972-1973: Glen Oaks Community College: Associates Degree –
                  Business Management.
                </li>
              </ul>
            </div>
            <h3
              style={{
                fontWeight: 700,
              }}
            >
              Appearances
            </h3>
            <div
              style={{
                padding: '20px',
                textAlign: 'left',
              }}
              className='media-text'
            >
              <ul className='media-text'>
                <li>
                  Saddleback Church/San Diego – educator of faith growth
                  training
                </li>
                <li>
                  Camp Pendleton – instructor of Marine recruits of Biblical
                  knowledge and principles of marriage and family life.
                </li>
                <li>
                  Center for Enriching Relationships: financial literacy
                  workshops for pastors and spouses.
                </li>
                <li>
                  Skyline Church: panel member of Biblical-based financial
                  literacy.
                </li>
                <li>Maranatha Church: Biblical-based financial literacy.</li>
                <li>
                  Various churches: financial literacy training program
                  entitled: Planned Spending Program.
                </li>
                <li>Various churches: Guest pastor</li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {showPressReleaseModal && (
        <div style={modalStyles}>
          <div style={modalContentStyles}>
            <button
              onClick={handleClose}
              style={closeButtonStyles}
              aria-label='Close modal'
            >
              &times;
            </button>
            <div
              style={{ background: '#f1e0c2cf', padding: '20px' }}
              className=' text-center'
            >
              <h3 style={{ margin: '0px', fontWeight: 700 }}>Press Release</h3>
            </div>
            <div
              style={{
                padding: '20px ',
                textAlign: 'left',
                fontSize: '18px',
                fontWeight: 500,
              }}
              className='media-text'
            >
              <p>
                <strong>Contact:</strong> Jerry Stephen Troyer
              </p>
              <p>
                <strong>Email:</strong> TroyerJS@proton.me
              </p>
              <p>
                <strong>Phone:</strong> 760-389-8048
              </p>
              <p>
                Jerry Troyer Releases Debut Book:{' '}
                <strong>Awakening to the Truth</strong>—A Journey to discover
                what you may do in situations if you were God and also come to
                recognitions of what the true living God will do in those same
                situations.
              </p>
              <p>
                San Diego, CA – [Insert Date] – Jerry Troyer, a seasoned
                Christian leader and chaplain, is proud to announce the release
                of his debut book, Awakening to the Truth. Written for those
                exploring their faith as well as non-believers, this powerful
                work invites readers into a profound conversation about
                spirituality, truth, and the search for meaning in today’s
                world.
              </p>
              <p>
                Awakening to the Truth draws upon Jerry Troyer’s extensive
                experience in biblical counseling and pastoral care, offering a
                unique perspective that resonates with a wide audience. Whether
                you’re navigating your own spiritual journey, seeking answers to
                life’s big questions, or simply curious about the Christian
                faith, this book serves as a thought-provoking guide.
              </p>
              <p>
                “Awakening to the Truth is not just a book; it’s a reflection of
                my own journey and the experiences I’ve heard from countless
                individuals and families over the years,” said Jerry Troyer. “I
                wrote this book to provide a space for readers to explore their
                beliefs, ask tough questions, and find comfort in the wisdom of
                faith.”
              </p>
              <p>
                In Awakening to the Truth, Jerry addresses critical topics such
                as the challenges of belief, the intersection of faith and daily
                life, and the importance of finding one’s own path to truth. His
                thoughtful insights and practical advice offer readers a
                compelling and accessible entry point into the world of
                Christian spirituality.
              </p>
              <p>
                Awakening to the Truth is now available for purchase at [insert
                where the book is available: bookstores, online retailers,
                etc.]. For more information about the book or to request an
                interview with Jerry Troyer, please contact him at
                TroyerJS@proton.me and 760-389-8048.
              </p>
              <p>
                <strong>About Jerry Troyer - History</strong>
              </p>
              <p>
                Jerry Stephen Troyer is an accomplished Christian leader with a
                diverse background in spiritual counseling, pastoral care, and
                financial planning. As a chaplain, ordained elder, and founder
                of a non-profit organization, Jerry has dedicated his life to
                guiding others through their spiritual and practical challenges.
                Awakening to the Truth is his first book, written to inspire
                both believers and non-believers on their journey toward
                understanding and faith.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Media;
