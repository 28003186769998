// import React, { useState, useEffect } from 'react';
// import emailjs from 'emailjs-com';
// import AOS from 'aos';

// const initialState = {
//   name: '',
//   email: '',
//   message: '',
//   responseDate: '',
// };

// export const Contact = (props) => {
//   const [{ name, email, message, responseDate }, setState] =
//     useState(initialState);
//   const [newsletterEmail, setNewsletterEmail] = useState('');
//   const [loading, setLoading] = useState(false); //
//   const [loadingNewsletter, setLoadingNewsletter] = useState(false); //
//   const [formValid, setFormValid] = useState(false);
//   const [modalContent, setModalContent] = useState('');
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     AOS.init({
//       duration: 1500,
//     });
//   }, []);

//   // Function to validate the form
//   const validateForm = () => {
//     setFormValid(name && email && message && responseDate);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({ ...prevState, [name]: value }));
//     validateForm();
//   };

//   const clearState = () => {
//     setState({ ...initialState });
//     validateForm();
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);

//     emailjs
//       .sendForm(
//         process.env.REACT_APP_EMAILJS_SERVICE_ID,
//         process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
//         e.target,
//         process.env.REACT_APP_EMAILJS_USER_ID
//       )
//       .then(
//         (result) => {
//           setLoading(false);
//           clearState();
//           setModalContent('Message sent successfully!');
//           setShowModal(true);
//         },
//         (error) => {
//           setLoading(false);
//           setModalContent('Failed to send message. Please try again.');
//           setShowModal(true);
//         }
//       );
//   };

//   const handleNewsletterSubmit = (e) => {
//     e.preventDefault();
//     setLoadingNewsletter(true);

//     emailjs
//       .send('service_ux1w6ns', 'template_a8e1i3b', '1wB0ly64Uuo5RU8zU', {
//         email: newsletterEmail,
//       })
//       .then(
//         (result) => {
//           setLoadingNewsletter(false);
//           setNewsletterEmail('');
//           setModalContent('Subscribed to newsletter successfully!');
//           setShowModal(true);
//         },
//         (error) => {
//           setLoadingNewsletter(false);
//           setModalContent('Failed to subscribe. Please try again.');
//           setShowModal(true);
//         }
//       );
//   };

//   return (
//     <div id='contact'>
//       <div className='container'>
//         <div className='cont'>
//           <div className='contact-form-container' data-aos='fade-right'>
//             <div className='section-title'>
//               <h2>Get In Touch</h2>
//               <p style={{ color: '#fff' }}>
//                 Please fill out the form below to send us an email, and we will
//                 get back to you as soon as possible.
//               </p>
//             </div>
//             <form
//               name='sentMessage'
//               validate
//               onSubmit={handleSubmit}
//               data-aos='fade-up'
//             >
//               <input
//                 type='text'
//                 id='name'
//                 name='name'
//                 className='form-control'
//                 placeholder='Name'
//                 required
//                 onChange={handleChange}
//               />
//               <input
//                 type='email'
//                 id='email'
//                 name='email'
//                 className='form-control'
//                 placeholder='Email'
//                 required
//                 onChange={handleChange}
//               />
//               <input
//                 type='text'
//                 id='responseDate'
//                 name='responseDate'
//                 className='form-control'
//                 placeholder='Expected Response Date'
//                 required
//                 onChange={handleChange}
//               />
//               <textarea
//                 name='message'
//                 id='message'
//                 className='form-control'
//                 rows='4'
//                 placeholder='Message'
//                 required
//                 onChange={handleChange}
//               ></textarea>
//               <button
//                 type='submit'
//                 className='btn btn-custom btn-lg'
//                 disabled={!formValid || loading}
//               >
//                 {loading ? 'Sending...' : 'Send Message'}
//               </button>
//             </form>
//           </div>

//           <div className='newsletter-form' data-aos='fade-left'>
//             <h3>Subscribe to Our Newsletter</h3>
//             <form onSubmit={handleNewsletterSubmit}>
//               <div className='form-group'>
//                 <input
//                   type='email'
//                   id='newsletter-email'
//                   name='newsletter-email'
//                   className='form-control'
//                   placeholder='Enter your email'
//                   value={newsletterEmail}
//                   onChange={(e) => setNewsletterEmail(e.target.value)}
//                   required
//                 />
//               </div>
//               <button
//                 type='submit'
//                 className='btn btn-custom btn-lg'
//                 disabled={!newsletterEmail || loadingNewsletter}
//               >
//                 {loadingNewsletter ? 'Subscribing...' : 'Subscribe'}
//               </button>
//             </form>
//           </div>
//         </div>

//         <div className='social'>
//           <ul>
//             <li>
//               <a
//                 href='https://www.facebook.com/profile.php?id=61564395603727&mibextid=ZbWKwL'
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <i className='fa fa-facebook'></i>
//               </a>
//             </li>
//             <li>
//               <a
//                 href={props.data ? props.data.twitter : '/'}
//                 target='_blank'
//                 rel='noopener noreferrer'
//               >
//                 <i className='fa fa-instagram'></i>
//               </a>
//             </li>
//             {/* <li>
//               <a href={props.data ? props.data.youtube : '/'}>
//                 <i className='fa fa-youtube'></i>
//               </a>
//             </li> */}
//           </ul>
//         </div>
//       </div>

//       {showModal && (
//         <div
//           className='modal'
//           onClick={() => setShowModal(false)}
//           data-aos='fade-in'
//         >
//           <div className='modal-content'>
//             <span className='close' onClick={() => setShowModal(false)}>
//               &times;
//             </span>
//             <p style={{ color: '#000' }}>{modalContent}</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Contact;

import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import AOS from 'aos';

const initialState = {
  name: '',
  email: '',
  message: '',
  responseDate: '',
};

export const Contact = (props) => {
  const [{ name, email, message, responseDate }, setState] =
    useState(initialState);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const validateForm = () => {
    setFormValid(name && email && message && responseDate);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    validateForm();
  };

  const clearState = () => {
    setState({ ...initialState });
    validateForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          setLoading(false);
          clearState();
          setModalContent('Message sent successfully!');
          setShowModal(true);
        },
        (error) => {
          setLoading(false);
          setModalContent('Failed to send message. Please try again.');
          setShowModal(true);
        }
      );
  };

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    setLoadingNewsletter(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_NEWSLETTER,
        e.target, // Passing the form reference here
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          setLoadingNewsletter(false);
          setNewsletterEmail(''); // Clear the input after successful submission
          setModalContent('Subscribed to newsletter successfully!');
          setShowModal(true);
        },
        (error) => {
          setLoadingNewsletter(false);
          setModalContent('Failed to subscribe. Please try again.');
          setShowModal(true);
        }
      );
  };

  // const handleNewsletterSubmit = (e) => {
  //   e.preventDefault();
  //   setLoadingNewsletter(true);

  //   emailjs
  //     .send(
  //       process.env.REACT_APP_EMAILJS_SERVICE_ID,
  //       'template_ybb3qr6',
  //       // {
  //       //   email: newsletterEmail,
  //       // },
  //       '1wB0ly64Uuo5RU8zU'
  //     )
  //     .then(
  //       (result) => {
  //         setLoadingNewsletter(false);
  //         setNewsletterEmail('');
  //         setModalContent('Subscribed to newsletter successfully!');
  //         setShowModal(true);
  //       },
  //       (error) => {
  //         setLoadingNewsletter(false);
  //         setModalContent('Failed to subscribe. Please try again.');
  //         setShowModal(true);
  //       }
  //     );
  // };

  return (
    <div id='contact'>
      <div className='container'>
        <div className='cont'>
          <div className='contact-form-container' data-aos='fade-right'>
            <div className='section-title'>
              <h2>Get In Touch</h2>
              <p style={{ color: '#fff' }}>
                Please fill out the form below to send us an email, and we will
                get back to you as soon as possible.
              </p>
            </div>
            <form
              name='sentMessage'
              validate
              onSubmit={handleSubmit}
              data-aos='fade-up'
            >
              <input
                type='text'
                id='name'
                name='name'
                className='form-control'
                placeholder='Name'
                required
                onChange={handleChange}
                value={name} // Bind value to state
              />
              <input
                type='email'
                id='email'
                name='email'
                className='form-control'
                placeholder='Email'
                required
                onChange={handleChange}
                value={email} // Bind value to state
              />
              <input
                type='text'
                id='responseDate'
                name='responseDate'
                className='form-control'
                placeholder='Expected Response Date'
                required
                onChange={handleChange}
                value={responseDate} // Bind value to state
              />
              <textarea
                name='message'
                id='message'
                className='form-control'
                rows='4'
                placeholder='Message'
                required
                onChange={handleChange}
                value={message} // Bind value to state
              ></textarea>
              <button
                type='submit'
                className='btn btn-custom btn-lg'
                disabled={!formValid || loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
          <div className='newsletter-form' data-aos='fade-left'>
            <h3>Subscribe to Our Newsletter</h3>
            <form
              name='sentNewsletter'
              validate
              onSubmit={handleNewsletterSubmit}
            >
              <div className='form-group'>
                <input
                  type='email'
                  id='newsletter-email'
                  name='email' // Ensure the name matches the template field in EmailJS
                  className='form-control'
                  placeholder='Enter your email'
                  value={newsletterEmail}
                  onChange={(e) => setNewsletterEmail(e.target.value)}
                  required
                />
              </div>
              <button
                type='submit'
                className='btn btn-custom btn-lg'
                disabled={!newsletterEmail || loadingNewsletter}
              >
                {loadingNewsletter ? 'Subscribing...' : 'Subscribe'}
              </button>
            </form>
          </div>

          {/* <div className='newsletter-form' data-aos='fade-left'>
            <h3>Subscribe to Our Newsletter</h3>
            <form
              name='sentNewsletter'
              validate
              onSubmit={handleNewsletterSubmit}
            >
              <div className='form-group'>
                <input
                  type='email'
                  id='newsletter-email'
                  name='newsletter-email'
                  className='form-control'
                  placeholder='Enter your email'
                  value={newsletterEmail}
                  onChange={(e) => setNewsletterEmail(e.target.value)}
                  required
                />
              </div>
              <button
                type='submit'
                className='btn btn-custom btn-lg'
                disabled={!newsletterEmail || loadingNewsletter}
              >
                {loadingNewsletter ? 'Subscribing...' : 'Subscribe'}
              </button>
            </form>
          </div> */}
        </div>

        <div className='social'>
          <ul>
            <li>
              <a
                href='https://www.facebook.com/profile.php?id=61564395603727&mibextid=ZbWKwL'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-facebook'></i>
              </a>
            </li>
            <li>
              <a
                href={props.data ? props.data.twitter : '/'}
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-instagram'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {showModal && (
        <div
          className='modal'
          onClick={() => setShowModal(false)}
          data-aos='fade-in'
        >
          <div className='modal-content'>
            <span className='close' onClick={() => setShowModal(false)}>
              &times;
            </span>
            <p style={{ color: '#000' }}>{modalContent}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
