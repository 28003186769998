import React, { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { About } from './components/about';
import { Testimonials } from './components/testimonials';
import { Contact } from './components/contact';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import './App.css';
import WelcomeNote from './components/welcome-note';
import { Books } from './components/books';
import { NewProject } from './components/new-project';
import Media from './components/media';
import MediaQuestions from './components/media-questions';
import BlogSlider from './components/blog';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <WelcomeNote />
      <About data={landingPageData.About} />
      <Testimonials data={landingPageData.Testimonials} />
      <Books />
      <BlogSlider />
      <NewProject />
      <Media />
      <MediaQuestions />

      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
