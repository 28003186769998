import React, { useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Header = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <header id='header' style={{ position: 'relative' }}>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            {/* <div className='row'> */}
            <div className=' mx-auto intro-text'>
              {/* <a
                className='navbar-brand page-scroll'
                href='#page-top'
                style={{ position: 'relative', fontSize: '24px' }}
              >
                Jerry Stephen Troyer
                <br />
                <span
                  style={{
                    fontStyle: 'italic',
                    fontSize: '14px',
                    textTransform: 'lowercase',
                    position: 'absolute',
                    left: '127px',
                  }}
                >
                  author
                </span>
              </a> */}
              <div className='header-link-container'>
                <a
                  className='navbar-brand page-scroll header-link'
                  href='#page-top'
                >
                  Jerry Stephen Troyer
                  <br />
                  <span className='header-link-span'>author</span>
                </a>
              </div>
              <h1 data-aos='fade-down'>
                {props.data ? props.data.title : 'Loading'}
              </h1>
              <p>
                <Typewriter
                  options={{
                    strings: [
                      "This book delves into the possibility of you being God. There are stories that describe actual events from real people's lives.",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </p>
              <a
                data-aos='fade-up'
                href='#about'
                className='btn btn-custom btn-lg page-scroll'
              >
                About Author
              </a>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};
